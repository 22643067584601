import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig.js';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Card } from 'antd';

const AdminLoginPage = () => {
  const [emailOrHandle, setEmailOrHandle] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const signInHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      let email = emailOrHandle;
      if (!emailOrHandle.includes('@')) {
        // If the input is a handle, find the corresponding email
        const userProfileRef = collection(db, 'userProfiles');
        const q = query(userProfileRef, where('handle', '==', emailOrHandle));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          email = querySnapshot.docs[0].data().email;
        } else {
          throw new Error('User not found');
        }
      }

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;
      const userProfileRef = collection(db, 'userProfiles');
      const q = query(userProfileRef, where('uid', '==', uid), where('isAdmin', '==', true));

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        navigate('/');
      } else {
        setError('Access denied. Only admin users are allowed.');
      }
    } catch (error) {
      setError('Login failed: ' + error.message);
      console.error('Error during admin login: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin-login-page">
      <Card title="Dayze Admin Dashboard Login">
        {error && <div className="error-message">{error}</div>}
        <Form onSubmit={signInHandler}>
          <Form.Item>
            <Input
              type="text"
              value={emailOrHandle}
              onChange={(e) => setEmailOrHandle(e.target.value)}
              placeholder="Email or Username"
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={signInHandler} loading={isLoading}>
              Log In
            </Button>
          </Form.Item>
        </Form>
        <Link to="/forgot-password">Forgot Password?</Link>
      </Card>
    </div>
  );
};

export default AdminLoginPage;
