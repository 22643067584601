import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import AdminLoginPage from './components/AdminLoginPage';
import Dashboard from './components/Dashboard';
import Notifications from './components/Notifications';
import Users from './components/Users';

const App = () => {
  return (
    <Router>
      <AppLayout>
        <Routes>
          {/* <Route path="/login" element={<AdminLoginPage />} /> */}
          <Route path="/" element={<Dashboard />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/users" element={<Users />} />
          <Route path="/login" element={<AdminLoginPage />} />
          {/* Add other routes here */}
        </Routes>
      </AppLayout>
    </Router>
  );
};

export default App;