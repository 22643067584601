import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { HomeOutlined, UserOutlined, NotificationOutlined } from '@ant-design/icons';
import logo from '../assets/dayze-logo-full.svg'; // Replace with the path to your logo
import { useNavigate } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig.js'; // Make sure this path is correct

const { Sider } = Layout;

const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false); // State to track user authentication

  const navigate = useNavigate();

  useEffect(() => {
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsUserSignedIn(!!user); // Update state based on user's sign-in status
    });

    // Collapse the sidebar by default on mobile screens
    const handleResize = () => setCollapsed(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    handleResize();

    // Cleanup
    return () => {
      unsubscribe();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const toggleCollapsed = () => setCollapsed(!collapsed);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      width={256}
      style={{
        height: '100vh',
        overflow: 'auto',
        background: 'white',
        borderRight: '0.1px solid #d9d9d9',
      }}
    >
      <div style={{
        height: '64px',
        display: 'flex',
        background: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
      }}>
        <img src={logo} alt="Logo" style={{ maxHeight: '100%', maxWidth: '100%' }} />
      </div>
      <Menu mode="inline" theme="light">
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="users" icon={<UserOutlined />}>
          <Link to="/users">Users</Link>
        </Menu.Item>
        <Menu.Item key="notifications" icon={<NotificationOutlined />}>
          <Link to="/notifications">Notifications</Link>
        </Menu.Item>
        {/* Conditionally render Sign Out link */}
        {isUserSignedIn && (
          <Menu.Item key="signOut" icon={<UserOutlined />} onClick={handleSignOut}>
            Sign Out
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
};

export default SideMenu;
