import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { Form, Input, Button, DatePicker, Table, AutoComplete, notification, Tabs, Select } from 'antd';
import axios from 'axios';
import './Notifications.css';
import { auth } from '../firebaseConfig.js'; // Make sure this path is correct

const { TabPane } = Tabs;

const Notifications = () => {
  const [subscribedUsers, setSubscribedUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [sortState] = useState({ columnKey: 'timestamp', order: 'descend' }); // Set initial sorting state
  const [scheduledNotifications, setScheduledNotifications] = useState([]);
  const [form] = Form.useForm();
  const [premadeNotifications] = useState([
    { title: "🎉 Welcome to Dayze!", message: "Thanks for joining our community! 🌟 Start tracking your days now and make every moment count! 📅✨" },
    { title: "🚀 New Feature Alert", message: "Check out our latest mood tracking feature! 😊😐😔 How are you feeling today? Let's record it!" },
    { title: "🌈 Weekly Reflection Time", message: "It's time for your weekly reflection! 🤔 What was your highlight this week? Share your thoughts! 💭" },
    { title: "🏆 Milestone Achieved", message: "Congratulations! 🎊 You've been using Dayze for 30 days straight. You're on fire! 🔥 Keep it up!" },
    { title: "🧘 Mindfulness Reminder", message: "Take a moment to breathe and center yourself. 😌 Your well-being matters. You've got this! 💪" },
    { title: "🎭 Time for a Handle!", message: "Hey there, mystery user! 🕵️‍♂️ We noticed you haven't picked a handle yet. Let's give you an identity makeover! 🌟 Choose your unique handle and stand out in the Dayze crowd! 🎨✨" }
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      }
    });

    axios.get('https://api.dayze.me/api/subscriptions')
      .then(response => {
        const formattedData = response.data.map(sub => {
          let formattedTimestamp = 'Unknown';
          if (sub.timestamp && sub.timestamp._seconds !== undefined) {
            const date = new Date(sub.timestamp._seconds * 1000); // Convert seconds to milliseconds
            formattedTimestamp = date.toLocaleString(); // Convert to local date string
          }

          return {
            ...sub,
            'subscription.endpoint': sub.subscription.endpoint,
            'subscription.keys.auth': sub.subscription.keys.auth,
            'subscription.keys.p256dh': sub.subscription.keys.p256dh,
            timestamp: formattedTimestamp
          };
        });

        // Remove duplicates based on uid
        const uniqueData = formattedData.reduce((acc, current) => {
          const x = acc.find(item => item.uid === current.uid);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        setSubscribedUsers(uniqueData);
        setAutoCompleteOptions(uniqueData.map(sub => ({ value: sub.uid })));
      })
      .catch(error => {
        console.error('Error fetching subscriptions:', error);
      });

    // Fetch scheduled notifications
    axios.get('https://api.dayze.me/api/scheduled-notifications')
      .then(response => {
        setScheduledNotifications(response.data);
      })
      .catch(error => {
        console.error('Error fetching scheduled notifications:', error);
      });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'Endpoint',
      dataIndex: 'subscription.endpoint', // Adjusted for nested field
      key: 'endpoint',
    },
    {
      title: 'Auth Key',
      dataIndex: 'subscription.keys.auth', // Adjusted for nested field
      key: 'auth',
    },
    {
      title: 'P256DH Key',
      dataIndex: 'subscription.keys.p256dh', // Adjusted for nested field
      key: 'p256dh',
    },
    {
      title: 'Date Created',
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: (a, b) => {
        const dateA = new Date(a.timestamp);
        const dateB = new Date(b.timestamp);
        return dateA - dateB;
      },
      defaultSortOrder: 'descend', // Set default sort order to descending
    },

    // Add more columns as needed
  ];

  const scheduledColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Scheduled For',
      dataIndex: 'scheduledFor',
      key: 'scheduledFor',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => cancelScheduledNotification(record.id)}>Cancel</Button>
      ),
    },
  ];

  const onFinish = (values) => {
    const targetSubscriptions = selectedUserId
      ? subscribedUsers.filter(user => user.uid === selectedUserId).map(user => user.subscription)
      : subscribedUsers.map(user => user.subscription);
  
    const notificationData = {
      title: values.title,
      message: values.message,
      url: values.url, // Include the URL
      date: values.date ? values.date.format() : null,
      subscriptions: targetSubscriptions
    };

    const endpoint = values.date
      ? 'https://api.dayze.me/api/schedule-notification'
      : 'https://api.dayze.me/api/send-notification';

    axios.post(endpoint, notificationData)
      .then(response => {
        console.log('Notification scheduled/sent:', response.data);
        notification.success({
          message: values.date ? 'Notification Scheduled' : 'Notification Sent',
          description: values.date ? 'Your notification has been scheduled.' : 'Your notification has been sent successfully.',
          placement: 'bottomRight'
        });
        if (values.date) {
          setScheduledNotifications([...scheduledNotifications, response.data]);
        }
      })
      .catch(error => {
        console.error('Error sending notification:', error);
        notification.error({
          message: 'Notification Failed',
          description: 'There was an error sending the notification.',
          placement: 'bottomRight'
        });
      });
  };

  const cancelScheduledNotification = (id) => {
    axios.delete(`https://api.dayze.me/api/scheduled-notifications/${id}`)
      .then(() => {
        setScheduledNotifications(scheduledNotifications.filter(n => n.id !== id));
        notification.success({
          message: 'Notification Cancelled',
          description: 'The scheduled notification has been cancelled.',
          placement: 'bottomRight'
        });
      })
      .catch(error => {
        console.error('Error cancelling notification:', error);
        notification.error({
          message: 'Cancellation Failed',
          description: 'There was an error cancelling the notification.',
          placement: 'bottomRight'
        });
      });
  };

  const onSelectUser = (value) => {
    setSelectedUserId(value);
  };

  const onPremadeSelect = (index) => {
    const selectedNotification = premadeNotifications[index];
    form.setFieldsValue({
      title: selectedNotification.title,
      message: selectedNotification.message,
    });
  };

  return (
    <div className="notifications-container">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Subscribed Users" key="1">
          <div className="table-container">
            <Table
              dataSource={subscribedUsers}
              columns={columns}
              rowKey={(record) => record.uid + record.timestamp} // Ensure unique key
              scroll={{ x: 'max-content' }} // Adjusts table scrolling
              sortState={sortState}
              pagination={{ responsive: true }} // Adds responsive pagination
            />
          </div>
        </TabPane>
        <TabPane tab="Scheduled Notifications" key="2">
          <div className="table-container">
            <Table
              dataSource={scheduledNotifications}
              columns={scheduledColumns}
              rowKey="id"
              scroll={{ x: 'max-content' }}
              pagination={{ responsive: true }}
            />
          </div>
        </TabPane>
      </Tabs>
      <Form form={form} layout="vertical" onFinish={onFinish} className="notification-form">
        <Form.Item name="premade" label="Premade Notifications">
          <Select
            placeholder="Select a fun, premade notification 😄"
            onChange={onPremadeSelect}
          >
            {premadeNotifications.map((notification, index) => (
              <Select.Option key={index} value={index}>
                {notification.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="uid" label="User ID (optional)">
          <AutoComplete
            options={autoCompleteOptions}
            onSelect={onSelectUser}
            placeholder="Enter User ID to target a specific user"
          />
        </Form.Item>
        {selectedUserId && <div className="selected-user-bubble">{selectedUserId}</div>}
        <Form.Item name="title" label="Notification Title" rules={[{ required: true }]}>
          <Input placeholder="Enter Notification Title" />
        </Form.Item>
        <Form.Item name="message" label="Notification Message" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="url" label="Target URL (optional)">
          <Input placeholder="Enter the target URL" />
        </Form.Item>
        <Form.Item name="date" label="Schedule Date">
          <DatePicker showTime />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {form.getFieldValue('date') ? 'Schedule Notification' : 'Send Notification'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Notifications;
