import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { onAuthStateChanged } from 'firebase/auth'; // Import onAuthStateChanged
import { Table, Input, Card, Row, Col, Statistic } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import DayzeCoinIcon from '../assets/dayzecoin.svg'; // Adjust the path as necessary
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from '../features/users/userSlice';
import { auth } from '../firebaseConfig.js'; // Make sure this path is correct

const ProfessionalCard = ({ title, value, icon, unit }) => {
  return (
    <Col xs={24} sm={12} lg={6}>
      <Card hoverable style={{ textAlign: 'center', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Statistic title={title} value={value} prefix={icon} suffix={unit} />
      </Card>
    </Col>
  );
};

const calculateDayAge = (birthday) => {
  if (!birthday) return Number.MAX_SAFE_INTEGER; // Handle missing or invalid dates
  const today = new Date();
  const birthDay = new Date(birthday);
  const differenceInTime = today.getTime() - birthDay.getTime();
  return Math.floor(differenceInTime / (1000 * 3600 * 24));
};

const parseLocalDate = (dateString) => {
  if (!dateString) return null;
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};

const calculateDaysToNextBirthday = (birthday) => {
  if (!birthday) return null;

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time part to handle edge cases
  const birthDate = new Date(birthday);

  if (isNaN(birthDate.getTime())) {
    return null;
  }

  let nextBirthday = new Date(birthDate);
  nextBirthday.setFullYear(today.getFullYear());
  if (today > nextBirthday) {
    nextBirthday.setFullYear(today.getFullYear() + 1);
  }

  const differenceInTime = nextBirthday.getTime() - today.getTime();
  return Math.floor(differenceInTime / (1000 * 3600 * 24));
};




const Users = () => {
  const navigate = useNavigate();
  const { data: users, loading } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      }
    });

    dispatch(fetchUsers(searchTerm));

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch, navigate, searchTerm]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Handle',
      dataIndex: 'handle',
      key: 'handle',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Birthdate',
      dataIndex: 'birthday',
      key: 'birthday',
      render: birthday => birthday ? parseLocalDate(birthday).toLocaleDateString("en-US") : 'N/A',
      sorter: (a, b) => {
        const dateA = a.birthday ? new Date(a.birthday).getTime() : Number.MIN_SAFE_INTEGER;
        const dateB = b.birthday ? new Date(b.birthday).getTime() : Number.MIN_SAFE_INTEGER;
        return dateA - dateB;
      },
    },
    {
      title: 'Day Age',
      dataIndex: 'birthday',
      key: 'dayAge',
      render: birthday => birthday ? calculateDayAge(birthday) : 'N/A',
      sorter: (a, b) => calculateDayAge(a.birthday) - calculateDayAge(b.birthday),
    },
    {
      title: 'Days to Birthday',
      key: 'daysToBirthday',
      render: (record) => {
        const daysToBirthday = calculateDaysToNextBirthday(record.birthday);
        return daysToBirthday !== null ? daysToBirthday : 'N/A';
      },
      sorter: (a, b) => {
        const daysA = calculateDaysToNextBirthday(a.birthday);
        const daysB = calculateDaysToNextBirthday(b.birthday);
        return (daysA !== null ? daysA : Number.MAX_SAFE_INTEGER) - (daysB !== null ? daysB : Number.MAX_SAFE_INTEGER);
      },
    },        
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => (new Date(createdAt)).toLocaleString("en-US"), // Format date and time
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt), // Sort by date
    },
    {
      title: 'Events',
      dataIndex: 'eventCount',
      key: 'eventCount',
      render: eventCount => Number(eventCount) || 0, // Ensure the value is treated as a number
      sorter: (a, b) => (Number(a.eventCount) || 0) - (Number(b.eventCount) || 0), // Sort numerically
    },
    {
      title: 'People',
      dataIndex: 'peopleCount',
      key: 'peopleCount',
      render: peopleCount => Number(peopleCount) || 0, // Ensure the value is treated as a number
      sorter: (a, b) => (Number(a.peopleCount) || 0) - (Number(b.peopleCount) || 0), // Sort numerically
    },
    {
      title: 'DayzeCoin',
      dataIndex: 'dayzeCoin',
      key: 'dayzeCoin',
      sorter: (a, b) => {
        const aValue = Number(a.dayzeCoin) || 0; // Convert to number, default to 0 if NaN
        const bValue = Number(b.dayzeCoin) || 0; // Convert to number, default to 0 if NaN
        return aValue - bValue;
      },
    },
    {
      title: 'Last Seen',
      dataIndex: 'lastSeen',
      key: 'lastSeen',
      render: lastSeen => (new Date(lastSeen)).toLocaleString("en-US"), // Format date and time
      sorter: (a, b) => new Date(a.lastSeen) - new Date(b.lastSeen), // Sort by date
    },
  ];

  // Calculate total DayzeCoins
  const totalDayzeCoins = users.reduce((total, user) => {
    const dayzeCoinValue = Number(user.dayzeCoin) || 0; // Convert to number and handle NaN
    return total + dayzeCoinValue;
  }, 0);

  return (
    <div>
      <Row gutter={[24, 24]}>
        <ProfessionalCard
          title="Total Users"
          value={users.length}
          icon={<UserOutlined />}
        />
        <ProfessionalCard
          title="Total DayzeCoin in Circulation"
          value={totalDayzeCoins}
          icon={<img src={DayzeCoinIcon} alt="DayzeCoin" style={{ width: '1em', height: '1em' }} />}
        />
      </Row>

      <Input
        placeholder="Search by name or username"
        onChange={e => setSearchTerm(e.target.value)}
        style={{ marginBottom: 20, marginTop: 20, width: '100%' }}
      />
      {loading ? (
        <p>Loading users...</p>
      ) : (
        <Table 
          dataSource={users} 
          columns={columns} 
          pagination={{ responsive: true }} 
          scroll={{ x: 'max-content' }}
        />
      )}
    </div>
  );
};

export default Users;
