import React from 'react';
import { Layout } from 'antd';
import SideMenu from './SideMenu';

const { Content } = Layout;

const AppLayout = ({ children }) => {
  return (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
      <SideMenu />
      <Layout>
        <Content style={{ padding: '20px', overflow: 'initial' }}>
          <div className="site-layout-content">
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
