import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { onAuthStateChanged } from 'firebase/auth'; // Import onAuthStateChanged
import { Card, Row, Col, Statistic, Modal, Table } from 'antd';
import { UserOutlined, DollarOutlined, RiseOutlined, ClockCircleOutlined, SmileOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from '../features/users/userSlice';
import { auth } from '../firebaseConfig.js'; // Make sure this path is correct for your project structure

const ProfessionalCard = ({ title, value, icon, unit }) => {
  return (
    <Col xs={24} sm={12} lg={6}>
      <Card hoverable style={{ textAlign: 'center', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Statistic title={title} value={value} prefix={icon} suffix={unit} />
      </Card>
    </Col>
  );
};

const isWithinLastDays = (date, days) => {
  const now = new Date();
  const lastActiveDate = new Date(date);
  const timeDiff = now - lastActiveDate;
  return timeDiff < days * 24 * 60 * 60 * 1000;
};

const isDailyActive = (lastSeen) => isWithinLastDays(lastSeen, 1);
const isWeeklyActive = (lastSeen) => isWithinLastDays(lastSeen, 7);
const isMonthlyActive = (lastSeen) => isWithinLastDays(lastSeen, 30);

const calculateDaysSinceLastSeen = (lastSeen) => {
  const lastSeenDate = new Date(lastSeen);
  const today = new Date();
  return Math.floor((today - lastSeenDate) / (1000 * 60 * 60 * 24));
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { data: users } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dailyActiveUsersData, setDailyActiveUsersData] = useState([]);

  const [isWeeklyModalVisible, setIsWeeklyModalVisible] = useState(false);
  const [weeklyActiveUsersData, setWeeklyActiveUsersData] = useState([]);

  const [isMonthlyModalVisible, setIsMonthlyModalVisible] = useState(false);
  const [monthlyActiveUsersData, setMonthlyActiveUsersData] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // User not signed in, redirecting to login page
        navigate('/login'); // Replace '/login' with your login route
      }
    });

    dispatch(fetchUsers());

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch, navigate]);

  // Calculate active users
  const dailyActiveUsers = users.filter(user => isDailyActive(user.lastSeen)).length;
  const weeklyActiveUsers = users.filter(user => isWeeklyActive(user.lastSeen)).length;
  const monthlyActiveUsers = users.filter(user => isMonthlyActive(user.lastSeen)).length;

  const showDailyUsersModal = () => {
    setDailyActiveUsersData(users.filter(user => isDailyActive(user.lastSeen)));
    setIsModalVisible(true);
  };

  const showWeeklyUsersModal = () => {
    setWeeklyActiveUsersData(users.filter(user => isWeeklyActive(user.lastSeen)));
    setIsWeeklyModalVisible(true);
  };

  const showMonthlyUsersModal = () => {
    setMonthlyActiveUsersData(users.filter(user => isMonthlyActive(user.lastSeen)));
    setIsMonthlyModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleWeeklyModalClose = () => {
    setIsWeeklyModalVisible(false);
  };

  const handleMonthlyModalClose = () => {
    setIsMonthlyModalVisible(false);
  };

  // Define columns for the modal table
  const userColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Handle',
      dataIndex: 'handle',
      key: 'handle',
    },
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'Last Seen',
      dataIndex: 'lastSeen',
      key: 'lastSeen',
      render: lastSeen => (new Date(lastSeen)).toLocaleString("en-US"), // Format date and time
      sorter: (a, b) => new Date(a.lastSeen) - new Date(b.lastSeen), // Sort by date
    },
    {
      title: 'Days Since Last Seen',
      key: 'daysSinceLastSeen',
      render: (record) => calculateDaysSinceLastSeen(record.lastSeen),
    },
  ];

  // Extended Dummy KPI Data
  const kpiData = {
    totalUsers: 0,
    activeUsers: 0,
    newUsers: 100,
    revenue: 50000, // Example in dollars
    churnRate: 5, // Percentage of users who have stopped using the service
    averageSessionTime: 30, // Average session time in minutes
    customerSatisfaction: 4.5, // Customer satisfaction score out of 5
    totalSales: 1500, // Total number of sales
    // Add more KPIs as needed
  };

  const tableStyle = {
    maxHeight: '400px', // Adjust the height as needed
    overflowY: 'auto'
  };

  return (
    <div>
      <Row gutter={[24, 24]}>
        <ProfessionalCard
          title="Total Users"
          value={users.length} // Updated to use actual user count
          icon={<UserOutlined />}
        />

        <Col xs={24} sm={12} lg={6} onClick={showDailyUsersModal}>
          <Card hoverable style={{ textAlign: 'center', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Statistic title="Daily Active Users" value={dailyActiveUsers} prefix={<UserOutlined />} />
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={6} onClick={showWeeklyUsersModal}>
          <Card hoverable style={{ textAlign: 'center', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Statistic title="Weekly Active Users" value={weeklyActiveUsers} prefix={<UserOutlined />} />
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={6} onClick={showMonthlyUsersModal}>
          <Card hoverable style={{ textAlign: 'center', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Statistic title="Monthly Active Users" value={monthlyActiveUsers} prefix={<UserOutlined />} />
          </Card>
        </Col>

        <ProfessionalCard
          title="New Users"
          value={kpiData.newUsers}
          icon={<UserOutlined />}
        />
        <ProfessionalCard
          title="Revenue"
          value={`$${kpiData.revenue}`}
          icon={<DollarOutlined />}
        />
        <ProfessionalCard
          title="Churn Rate"
          value={kpiData.churnRate}
          icon={<RiseOutlined />}
          unit="%"
        />
        <ProfessionalCard
          title="Average Session Time"
          value={kpiData.averageSessionTime}
          icon={<ClockCircleOutlined />}
          unit="minutes"
        />
        <ProfessionalCard
          title="Customer Satisfaction"
          value={kpiData.customerSatisfaction}
          icon={<SmileOutlined />}
          unit="/ 5"
        />
        <ProfessionalCard
          title="Total Sales"
          value={kpiData.totalSales}
          icon={<ShoppingCartOutlined />}
          unit=""
        />
      </Row>

      <Modal
        title="Daily Active Users"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="80%"  // Adjust the width of the modal as needed
      >
        <Table
          dataSource={dailyActiveUsersData}
          columns={userColumns}
          style={tableStyle}
          pagination={false} // Disable pagination if you want all rows in a single scrollable view
        />
      </Modal>

      <Modal
        title="Weekly Active Users"
        open={isWeeklyModalVisible}
        onCancel={handleWeeklyModalClose}
        footer={null}
        width="80%"
      >
        <Table
          dataSource={weeklyActiveUsersData}
          columns={userColumns}
          style={tableStyle}
          pagination={false}
        />
      </Modal>

      <Modal
        title="Monthly Active Users"
        open={isMonthlyModalVisible}
        onCancel={handleMonthlyModalClose}
        footer={null}
        width="80%"
      >
        <Table
          dataSource={monthlyActiveUsersData}
          columns={userColumns}
          style={tableStyle}
          pagination={false}
        />
      </Modal>

    </div>
  );
};

export default Dashboard;